<template>
  <div
    class="role"
    v-loading.fullscreen.lock="listLoading || treeLoading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div v-if="hasPermission">
      <el-button
        class="btnCommon btnComonPlus rightTop"
        @click="createRole"
      >新增角色</el-button>
      <template v-if="index===0">
        <div class="simpleInfoPanel">
          <ul class="filtroPanel">
            <li>
              <span>角色：</span>
              <el-input
                v-model="form.role"
                placeholder="请输入角色名查询"
              ></el-input>
            </li>
            <li>
              <span>人员：</span>
              <el-input
                v-model="form.members"
                placeholder="输入姓名/用户名查询"
              ></el-input>
            </li>
            <li>
              <span>权限：</span>
              <el-cascader
                popper-class="black-select-panel"
                v-model="form.rights"
                :options="rightsOptions"
                placeholder="请选择权限"
                clearable
                :props="{
                expandTrigger: 'hover',
                checkStrictly: true,
                label: 'cnname',
                children: 'operateList2VOS',
                value: 'id'
              }"
                :show-all-levels="false"
                filterable
                @change="tellMe"
              ></el-cascader>
            </li>
            <li>
              <el-button
                class="btnPrimary btnCommon"
                @click="handleSearch"
              >查询</el-button>
            </li>
          </ul>
        </div>
        <div class="simpleInfoPanel mt15">
          <el-table
            :data="tableData"
            class="tbsWrap"
          >
            <el-table-column
              prop=""
              label="序号"
              width="80"
            >
              <template slot-scope="scope">
                {{scope.$index+1}}
              </template>
            </el-table-column>
            <el-table-column
              prop="role"
              label="角色名称"
            >
            </el-table-column>
            <el-table-column
              prop="member"
              label="成员"
            >
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="handlePanel">
                  <a @click="handleRights(scope.row)">权限维护</a>
                  <a @click="handleMembers(scope.row.id)">成员维护</a>
                  <a @click="deleteItem(scope.row)">删除</a>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="w-page-sty">
            <el-pagination
              background
              layout="total, prev, pager, next"
              v-if="params.total>10"
              :total="params.total"
              :page-size="params.pageSize"
              :current-page="params.pageNum"
              @current-change="handlePages"
            >
            </el-pagination>
          </div>
        </div>
      </template>
      <template v-if="index===1">
        <!-- 权限维护&新增角色 -->
        <div class="simpleInfoPanel w-is-scroll">
          <el-form
            ref="rightsForm"
            :model="rightsForm"
            label-width="122px"
          >
            <el-form-item
              label="角色："
              prop="role"
              :rules="[{ required: true, message: '请输入角色', trigger: 'blur' }]"
            >
              <el-input
                v-model="rightsForm.role"
                placeholder="请输入角色名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="权限过滤">
              <el-input
                placeholder="输入关键字进行过滤"
                v-model="filterText"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="">
              <el-tree
                :data="rightsTbs"
                :default-checked-keys="treeCheckedArr"
                show-checkbox
                node-key="id"
                ref="tree"
                :filter-node-method="filterNode"
                :props="{
                  children: 'configVOS',
                  label: 'cnname'
                }"
              >
              </el-tree>
            </el-form-item>
            <el-form-item label=" ">
              <el-button
                class="btnPrimary btnCommon"
                @click="saveRole('rightsForm')"
              >保存</el-button>
              <el-button
                class="btnCommon"
                @click="index=0"
              >取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <el-dialog
        class="manager-dialog"
        title="成员维护"
        :visible.sync="dialogMember"
      >
        <el-form :model="memberForm">
          <el-form-item label="当前成员：">
            <el-checkbox-group
              v-model="memberForm.member"
              v-if="memberList.length"
              @change="handleCurrentMember"
            >
              <el-checkbox
                v-for="item in memberList"
                :key="item.id"
                :label="item.id+','+item.name"
                :value="item.id+','+item.name"
              >{{item.name}}</el-checkbox>
            </el-checkbox-group>
            <!-- <ul v-if="memberList.length" class="memberList">
              <li
                v-for="item in memberList"
                :key="item.id"
                >{{item.name}}</li>
            </ul> -->
            <p v-else>暂无成员</p>
          </el-form-item>
          <el-form-item label="增加成员：">
            <el-autocomplete
              class="inline-input"
              v-model="memberForm.searchMemberName"
              :fetch-suggestions="querySearch"
              placeholder="输入人员姓名或用户名搜索添加"
              :trigger-on-focus="false"
              @input="searchMember"
              @select="handleSelect"
            >
              <template slot-scope="{ item }">
                {{ item.name }}
              </template>
            </el-autocomplete>
          </el-form-item>
        </el-form>
        <el-tree
          :data="memberData"
          show-checkbox
          node-key="nid"
          ref="memberTree"
          highlight-current
          default-expand-all
          :default-expanded-keys="defaultCheckList"
          :default-checked-keys="defaultCheckList"
          :props="{
            children: 'managementVOS',
            label: 'name'
          }"
          @check-change="handleMemberChange"
        >
          <template slot-scope="{ data }">
            <span class="el-tree-node__label">
              <i :class="data.icon"></i>{{ data.name }}
            </span>
          </template>
        </el-tree>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button class="cancelbtn" @click="dialogMember = false">取 消</el-button>
          <el-button
            type="primary"
            @click="saveMembers"
          >确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'role',
  components: {},
  data () {
    return {
      index: 0,
      form: {
        role: '',
        members: '',
        rights: []
      },
      rightsOptions: [],
      rightsTbs: [],
      tableData: [],
      rightSelectAll: false,
      rightSelectItemAll: [],
      rightsForm: {
        id: '',
        role: '',
        rights: []
      },
      rightsArr: [], // 保存所有的权限为数组，用来全选
      rightsItemList: [],
      dialogMember: false,
      memberForm: {
        id: null,
        member: [],
        searchMemberName: '',
        createMember: []
      },
      memberData: [],
      departmentList: [],
      filterText: '',
      checkedRights: [],
      treeCheckedArr: [], // 回显用的checked
      treeLoading: false,
      listLoading: false,
      memberList: [],
      autoMemberList: [],
      userAuthorities: [],
      hasPermission: true,
      hasPermissionAdd: true,
      hasPermissionEdit: true,
      hasPermissionDel: true,
      defaultCheckList: [], // 成员维护默认选中
      params: {
        pageNum: 1,
        pageSize: 10,
        pages: 1,
        total: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    },
    memberList (newVal, oldVal) {
    }
  },
  mounted () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 88
    })
    if (this.userAuthorities.length) {
      this.hasPermission = true
      if (this.$route.query.index) { // 其他页面过来默认打开新增角色
        this.createRole()
      }
      this.getDataList({})
      this.getDataRightsFiltrate() // 获取权限列表，筛选用
      if (this.userAuthorities[0].func.indexOf('add') !== -1) {
        this.hasPermissionAdd = true
      } else {
        this.hasPermissionAdd = false
      }
      if (this.userAuthorities[0].func.indexOf('edit') !== -1) {
        this.hasPermissionEdit = true
      } else {
        this.hasPermissionEdit = false
      }
      if (this.userAuthorities[0].func.indexOf('del') !== -1) {
        this.hasPermissionDel = true
      } else {
        this.hasPermissionDel = false
      }
    } else {
      this.hasPermission = false
    }
  },
  methods: {
    hasnotPermission () {
      this.$message.error({
        message: '暂无权限，如需开通权限，请联系管理员开通'
      })
    },
    createRole () {
      if (!this.hasPermissionAdd) {
        this.hasnotPermission()
        return false
      }
      this.index = 1
      this.resetForm()
      this.getRightsList({})
    },
    resetForm () {
      this.rightsForm = {
        id: '',
        role: '',
        rights: []
      }
      this.rightsTbs = []
    },
    currentMemberTree (val) {
      // const res = this.$refs.tree.getCheckedKeys().filter(item => {
      //   return item
      // })
      // console.log('res', res)
      // this.checkedRights = res.map(item => {
      //   const tempObj = {}
      //   const tempArr = item.split(',')
      //   tempObj.menuId = tempArr[0]
      //   tempObj.func = tempArr[1]
      //   return tempObj
      // })
      // console.log('checkedRights', this.checkedRights)
    },
    querySearch (queryString, cb) {
      const autoMemberList = this.autoMemberList
      const results = autoMemberList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    searchMember (key) {
      if (key) {
        const params = {
          keyword: key
        }
        this.axios.post(api.getUserBykeyword, params).then(res => {
          if (res.data.code === 0) {
            const resData = res.data.data
            this.autoMemberList = resData
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    handleSelect (item) {
      this.memberForm.member.push(item.id + ',' + item.name)
      this.$refs.memberTree.setCheckedKeys(this.memberForm.member)
      // this.$refs.memberTree.store.nodesMap[(item.id + ',' + item.name).toString()].expanded = true
    },
    filterNode (value, data) {
      if (!value) return true
      return data.cnname.indexOf(value) !== -1
    },
    handleSearch () {
      const params = {
        name: this.form.role,
        operateid: '',
        func: '',
        userName: this.form.members
      }
      if (this.form.rights.length > 0) {
        if ([...this.form.rights].pop().toString().indexOf(',') !== -1) { // 存在func的一级
          params.operateid = [...this.form.rights].pop().split(',')[0]
          params.func = [...this.form.rights].pop().split(',')[1]
        } else {
          params.operateid = [...this.form.rights].pop()
        }
      }
      this.params.pageNum = 1
      this.getDataList(params)
    },
    handleRights (data) {
      if (!this.hasPermissionEdit) {
        this.hasnotPermission()
        return false
      }
      this.resetForm()
      this.index = 1
      this.rightsForm.id = data.id
      this.rightsForm.role = data.role
      const params = {
        roleId: data.id
      }
      this.getRightsList(params)
    },
    getRightsList (params) {
      this.treeLoading = true
      this.treeCheckedArr = []
      // 一般configVOS为下级，configActions为具体操作，会存在两项都为null的情况，也存在单一为null的情况，但不存在都不为null的情况
      this.axios.post(api.getPermissionsConfigList, params).then(res => {
        if (res.data.code === 0) {
          this.rightsTbs = res.data.data.map(item => { // item 是一级
            // if (item.isSelect) { // 一级如果选中，那么勾选一级
            //   this.treeCheckedArr.push(item.id)
            // }
            if (item.configVOS && item.configVOS.length) { // 如果一级存在二级
              for (let i = 0; i < item.configVOS.length; i++) { // 遍历二级
                const item1 = item.configVOS[i] // item1 是二级
                // if (item1.isSelect) { // 二级如果选中，那么勾选二级
                //   this.treeCheckedArr.push(item1.id)
                // }
                if (item1.configVOS === null && item1.configActions === null) { // 存在这个情况：二级下configVOS和configActions都是null
                  if (item1.isSelect) {
                    this.treeCheckedArr.push(item1.id)
                  }
                } else if (item1.configVOS === null && item1.configActions !== null) { // 三级为实际操作
                  item1.configVOS = item1.configActions.map(ele => {
                    ele.cnname = ele.remark
                    ele.id = ele.action + ',' + item1.id + ',' + ele.id
                    if (ele.isSelect) { // 选中三级为实际操作的item
                      this.treeCheckedArr.push(ele.id)
                    }
                    return ele
                  })
                } else { // 三级为权限名称
                  for (let i = 0; i < item1.configVOS.length; i++) { // 遍历三级权限
                    const item2 = item1.configVOS[i] // item2 是三级
                    if (item2.configVOS === null && item2.configActions === null) { // 存在这个情况：三级下configVOS和configActions都是null
                      if (item2.isSelect) {
                        this.treeCheckedArr.push(item2.id)
                      }
                      console.log('三级下configVOS和configActions都是null')
                    } else if (item2.configVOS === null && item2.configActions !== null) { // 四级为实际操作
                      item2.configVOS = item2.configActions.map(ele => {
                        ele.cnname = ele.remark
                        ele.id = ele.action + ',' + item2.id + ',' + ele.id
                        if (ele.isSelect) { // 选中四级为实际操作的item
                          this.treeCheckedArr.push(ele.id)
                        }
                        return ele
                      })
                    } else { // 四级为权限名称
                      for (let i = 0; i < item2.configVOS.length; i++) { // 遍历四级权限
                        const item3 = item2.configVOS[i] // item3 是四级
                        // if (item3.isSelect) {
                        //   this.treeCheckedArr.push(item3.id)
                        // }
                        if (item3.configVOS === null && item3.configActions === null) { // 存在这个情况：四级下configVOS和configActions都是null
                          if (item3.isSelect) {
                            this.treeCheckedArr.push(item3.id)
                          }
                        } else if (item3.configVOS === null && item3.configActions !== null) { // 五级为实际操作
                          item3.configVOS = item3.configActions.map(ele => {
                            ele.cnname = ele.remark
                            ele.id = ele.action + ',' + item3.id + ',' + ele.id
                            if (ele.isSelect) { // 选中五级为实际操作的item
                              this.treeCheckedArr.push(ele.id)
                            }
                            return ele
                          })
                        } else { // 五级为权限名称
                          for (let i = 0; i < item3.configVOS.length; i++) { // 遍历五级权限
                            const item4 = item3.configVOS[i] // item4 为五级
                            if (item3.configVOS === null && item3.configActions === null) { // 存在这个情况：四级下configVOS和configActions都是null
                              if (item3.isSelect) {
                                this.treeCheckedArr.push(item3.id)
                              }
                              console.log('四级下configVOS和configActions都是null')
                            } else if (item3.configVOS === null && item3.configActions !== null) { // 五级为实际操作
                              item4.configVOS = item4.configActions.map(ele => {
                                ele.cnname = ele.remark
                                ele.id = ele.action + ',' + item4.id + ',' + ele.id
                                console.log('选中六级为实际操作的item')
                                if (ele.isSelect) { // 选中六级为实际操作的item
                                  this.treeCheckedArr.push(ele.id)
                                }
                                return ele
                              })
                            } else {
                              console.log('？')
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            } else {
              if (item.isSelect) { // 一级如果选中，那么勾选一级
                this.treeCheckedArr.push(item.id)
              }
            }
            return item
          })
          this.treeLoading = false
        }
      }, rej => {
        console.log(rej)
        this.treeLoading = false
      }).catch(err => {
        this.treeLoading = false
        console.log(err)
      })
    },
    handleCurrentMember (val) {
      this.$refs.memberTree.setCheckedKeys(val)
    },
    handleMembers (id) { // show成员维护面板
      if (!this.hasPermissionEdit) {
        this.hasnotPermission()
        return false
      }
      this.getDataDivision() // 获取部门列表
      this.memberForm.member = []
      this.defaultCheckList = []
      this.dialogMember = true
      this.memberForm.id = id
      const params = {
        roleId: id
      }
      this.axios.post(api.getUsersByRoleId, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.memberList = resData.map(item => {
            this.memberForm.member.push(item.id + ',' + item.name)
            this.defaultCheckList.push(item.id + ',' + item.name)
            return item
          })
          this.$refs.memberTree.setCheckedKeys(this.defaultCheckList)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleMemberChange (val) { // 成员维护tree选中事件
      this.memberForm.member = [] // 清空已存在的列表，在下方统一拿勾选的数据
      const tempArr = this.$refs.memberTree.getCheckedKeys().filter(item => { // 获得已选中项目
        return item
      })
      this.memberList = tempArr.map(item => { // 设置特别的id
        const tempArr = item.split(',')
        const tempObj = {
          id: tempArr[0],
          name: tempArr[1]
        }
        this.memberForm.member.push(tempArr[0] + ',' + tempArr[1]) // 设置已选成员选中
        return tempObj
      })
    },
    getDataDivision (type, id) { // 成员维护treeData
      this.axios.post(api.memberList, {
      }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
          this.memberData = resData.map(item => { // 项目
            if (item.type === 2) { // 类型（-1总帐号,0集团,1项目,2部门,3用户） ,
              item.icon = 'icon-division1'
              item.count && (item.name = item.name + '(' + item.count + '人)')
              if (item.managementVOS && item.managementVOS.length) {
                for (let i = 0; i < item.managementVOS.length; i++) { // 一级部门
                  const ele = item.managementVOS[i]
                  if (ele.type === 3) {
                    ele.nid = ele.id + ',' + ele.name
                    ele.icon = 'icon-member1'
                  } else {
                    ele.icon = 'icon-division1'
                    ele.name = ele.name + '(' + (ele.count || '0') + '人)'
                    if (ele.managementVOS && ele.managementVOS.length) {
                      for (let j = 0; j < ele.managementVOS.length; j++) { // 二级部门
                        const ele1 = ele.managementVOS[j]
                        if (ele1.type === 3) {
                          ele1.nid = ele1.id + ',' + ele1.name
                          ele1.icon = 'icon-member1'
                        } else {
                          ele1.icon = 'icon-division1'
                          ele1.name = ele1.name + '(' + (ele1.count || '0') + '人)'
                          if (ele1.managementVOS && ele1.managementVOS.length) {
                            for (let j = 0; j < ele1.managementVOS.length; j++) { // 二级部门
                              const ele2 = ele1.managementVOS[j]
                              if (ele2.type === 3) {
                                ele2.nid = ele2.id + ',' + ele2.name
                                ele2.icon = 'icon-member1'
                              } else {
                                ele2.icon = 'icon-division1'
                                ele2.name = ele2.name + '(' + (ele2.count || '0') + '人)'
                                if (ele2.managementVOS && ele2.managementVOS.length) {
                                  for (let j = 0; j < ele2.managementVOS.length; j++) { // 二级部门
                                    const ele3 = ele2.managementVOS[j]
                                    if (ele3.type === 3) {
                                      ele3.nid = ele3.id + ',' + ele3.name
                                      ele3.icon = 'icon-member1'
                                    } else {
                                      ele3.icon = 'icon-division1'
                                      ele3.name = ele3.name + '(' + (ele3.count || '0') + '人)'
                                      if (ele3.managementVOS && ele3.managementVOS.length) {
                                        for (let k = 0; k < ele3.managementVOS.length; k++) {
                                          const ele4 = ele3.managementVOS[k]
                                          if (ele4.type === 3) {
                                            ele4.icon = 'icon-member1'
                                            ele4.nid = ele4.id + ',' + ele4.name
                                          }
                                        }
                                      } else {
                                      }
                                    }
                                  }
                                } else {
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            } else {
              item.icon = 'icon-member1'
            }
            return item
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    saveMembers () { // 保存成员维护
      const params = {
        roleId: this.memberForm.id,
        userids: ''
      }
      params.userids = this.memberList.map(item => {
        return item.id
      }).join(',')
      this.axios.post(api.memberMaintenance, params).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.msg
          })
          this.getDataList({})
          this.dialogMember = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    deleteItem (data) {
      if (!this.hasPermissionDel) {
        this.hasnotPermission()
        return false
      }
      let tipsText = '是否删除角色 ' + data.role
      if (data.member) {
        tipsText = '如删除角色则角色下成员的对应权限将被取消，是否确定删除？'
      }
      this.$confirm(tipsText, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          roleId: data.id
        }
        this.axios.post(api.deleteRole, params).then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: res.data.msg
            })
            this.getDataList({})
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
      })
    },
    tellMe (val) {
      console.log(val)
    },
    setRightsFormRight () {
      for (let i = 0; i < this.rightsOption.length; i++) {
        const ele = this.rightsOption[i]
        this.rightsArr[ele.id] = []
        for (let j = 0; j < ele.list.length; j++) {
          const ele1 = ele.list[j]
          this.rightsArr[ele.id].push(ele1.id)
        }
        this.rightSelectItemAll.push(false)
      }
    },
    handleCheckAllChange (key, id) {
      if (key) {
        const tempArr = this.rightsArr.filter(item => {
          return item
        })
        for (let i = 0; i < tempArr.length; i++) {
          const element = tempArr[i]
          this.rightsItemList = this.rightsItemList.concat(element)
        }
        for (let j = 0; j < tempArr.length; j++) {
          this.rightSelectItemAll[j] = true
        }
      } else {
        this.rightSelectItemAll = []
        this.rightsItemList = []
      }
    },
    handleCheckItemAllChange (key, id) {
      if (key) {
        this.rightsItemList = this.rightsItemList.concat(this.rightsArr[id])
      } else {
        this.rightsItemList = this.rightsItemList.filter(item => {
          return this.rightsArr[id].indexOf(item) === -1
        })
      }
    },
    handleSuccess (resData) {
      if (resData.code === 0) {
        this.$message({
          type: 'success',
          message: resData.msg
        })
        this.getDataList({})
        this.resetForm()
        this.treeLoading = false
        this.index = 0
      }
    },
    saveRole (form) { // 增加角色
      this.$refs[form].validate(valid => {
        if (valid) {
          this.treeLoading = true
          const params = {
            roleName: '', // 角色名
            operateIds: [] // 权限id arr [{func:string,menuId:number}]
          }
          this.checkedRights = this.$refs.tree.getCheckedKeys().filter(item => {
            return item
          })
          const listHalfChecked = this.$refs.tree.getHalfCheckedKeys().map(item => {
            return {
              selectAll: 0,
              menuId: item,
              func: ''
            }
          })
          params.id = this.rightsForm.id
          params.roleName = this.rightsForm.role
          params.operateIds = this.checkedRights.map(item => {
            const tempObj = {
              selectAll: 1,
              menuId: '',
              func: ''
            }
            if (typeof item === 'number') {
              tempObj.menuId = item
            } else {
              const tempArr = item.split(',')
              tempObj.menuId = tempArr[1]
              tempObj.func = tempArr[0]
            }
            return tempObj
          })
          params.operateIds.push(...listHalfChecked)
          let apiName = 'addRole'
          if (params.id) {
            apiName = 'updateRole'
          }
          this.axios.post(api[apiName], params).then(res => {
            if (res.data.code === 0) {
              this.handleSuccess(res.data)
            } else {
              this.treeLoading = false
            }
          }, rej => {
            this.treeLoading = false
            console.log(rej)
          }).catch(err => {
            this.treeLoading = false
            console.log(err)
          })
        } else {
          // this.index = 0
        }
      })
    },
    handlePages (val) {
      this.params.pageNum = val
      this.getDataList()
    },
    getDataList (params) { // 获取角色表格列表数据
      this.listLoading = true
      Object.assign(this.params, params)
      this.axios.post(api.getRoleList, this.params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.tableData = resData.dataList.map(item => {
            const tempObj = {
              id: item.id,
              role: item.name,
              member: item.userName
            }
            return tempObj
          })
          this.params.total = resData.total
          this.params.pageNum = resData.pageNum
          this.listLoading = false
        }
      }, rej => {
        console.log(rej)
        this.listLoading = false
      }).catch(err => {
        console.log(err)
        this.listLoading = false
      })
    },
    getDataRightsFiltrate () {
      this.axios.post(api.getConfigList, {
      }).then(res => {
        if (res.data.code === 0) {
          this.rightsOptions = res.data.data.map(item => {
            if (item.operateList2VOS && item.operateList2VOS.length) {
              for (let i = 0; i < item.operateList2VOS.length; i++) {
                const ele = item.operateList2VOS[i]
                if (ele.type) {
                  ele.id = ele.id + ',' + ele.func
                }
                if (ele.operateList2VOS && ele.operateList2VOS.length) {
                  for (let j = 0; j < ele.operateList2VOS.length; j++) {
                    const ele1 = ele.operateList2VOS[j]
                    if (ele1.type) {
                      ele1.id = ele.id + ',' + ele1.func
                    }
                    if (ele1.operateList2VOS && ele1.operateList2VOS.length) {
                      for (let k = 0; k < ele1.operateList2VOS.length; k++) {
                        const ele2 = ele1.operateList2VOS[k]
                        if (ele2.type) {
                          ele2.id = ele2.id + ',' + ele2.func
                        }
                        if (ele2.operateList2VOS && ele2.operateList2VOS.length) {
                          for (let k = 0; k < ele2.operateList2VOS.length; k++) {
                            const ele3 = ele2.operateList2VOS[k]
                            if (ele3.type) {
                              ele3.id = ele3.id + ',' + ele3.func
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            return item
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.role
  position relative
  .rightTop
    position absolute
    right 20px
    top -50px
  .w-is-scroll
    height 638px
    overflow-y scroll
    .el-input
      width 80%
  .memberList
    li
      display inline-block
      margin 0 10px
.role>>>
  .el-dialog
    .el-checkbox__inner
      background-color #fff
    .el-checkbox__label
      color #606266
  .el-checkbox-group
    label
      margin-bottom 0 !important
  .el-autocomplete
    width 300px
</style>
